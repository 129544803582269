
  import { GForm } from '@/components/forms/GForm'
  import { Stock } from '@/entities/sales/Stock'
  import { fixPrice } from '@/utils/general'
  import { plainToInstance } from 'class-transformer'
  import { Component, Prop } from 'vue-property-decorator'
  import Components from '.'

@Component({
  components: {
    ...Components,
  },
  methods: {
    fixPrice,
  },
})
  export default class PublicationFormWing extends GForm {
  @Prop({ type: Object, default: () => ({}) }) value!: Stock
  stock: Stock = plainToInstance(Stock, {})

  async mounted () {
    this.stock = await this.fetchData({
      query: { name: 'fetchSpecial', model: 'Stock', params: { id: this.value.id } },
      force: true,
    })
  }
  }
